import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Directive, HostListener, APP_INITIALIZER, NgModule } from '@angular/core';
import { defineCustomElements } from '@dvag/design-system/loader';
import { __decorate } from 'tslib';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/* eslint-disable */
const _c0 = ["*"];
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = new EventEmitter());
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let DxAccordion = class DxAccordion {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['openChange']);
  }
};
DxAccordion.ɵfac = function DxAccordion_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxAccordion)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxAccordion.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxAccordion,
  selectors: [["dx-accordion"]],
  inputs: {
    label: "label",
    open: "open"
  },
  outputs: {
    openChange: "openChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxAccordion_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxAccordion = __decorate([ProxyCmp({
  inputs: ['label', 'open']
})], DxAccordion);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxAccordion, [{
    type: Component,
    args: [{
      selector: 'dx-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['label', 'open'],
      outputs: ["openChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxAccordionGroup = class DxAccordionGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxAccordionGroup.ɵfac = function DxAccordionGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxAccordionGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxAccordionGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxAccordionGroup,
  selectors: [["dx-accordion-group"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxAccordionGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxAccordionGroup = __decorate([ProxyCmp({})], DxAccordionGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxAccordionGroup, [{
    type: Component,
    args: [{
      selector: 'dx-accordion-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxAccordionLegend = class DxAccordionLegend {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['openChange']);
  }
};
DxAccordionLegend.ɵfac = function DxAccordionLegend_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxAccordionLegend)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxAccordionLegend.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxAccordionLegend,
  selectors: [["dx-accordion-legend"]],
  inputs: {
    open: "open"
  },
  outputs: {
    openChange: "openChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxAccordionLegend_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxAccordionLegend = __decorate([ProxyCmp({
  inputs: ['open']
})], DxAccordionLegend);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxAccordionLegend, [{
    type: Component,
    args: [{
      selector: 'dx-accordion-legend',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['open'],
      outputs: ["openChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxAlert = class DxAlert {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxAlert.ɵfac = function DxAlert_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxAlert)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxAlert.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxAlert,
  selectors: [["dx-alert"]],
  inputs: {
    actionsdirection: "actionsdirection",
    content: "content",
    icon: "icon",
    sublabel: "sublabel",
    titlestring: "titlestring",
    type: "type",
    visible: "visible"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxAlert_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxAlert = __decorate([ProxyCmp({
  inputs: ['actionsdirection', 'content', 'icon', 'sublabel', 'titlestring', 'type', 'visible']
})], DxAlert);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxAlert, [{
    type: Component,
    args: [{
      selector: 'dx-alert',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['actionsdirection', 'content', 'icon', 'sublabel', 'titlestring', 'type', 'visible'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxAnnouncementBar = class DxAnnouncementBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxAnnouncementBar.ɵfac = function DxAnnouncementBar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxAnnouncementBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxAnnouncementBar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxAnnouncementBar,
  selectors: [["dx-announcement-bar"]],
  inputs: {
    color: "color",
    icon: "icon",
    iconcolor: "iconcolor"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxAnnouncementBar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxAnnouncementBar = __decorate([ProxyCmp({
  inputs: ['color', 'icon', 'iconcolor']
})], DxAnnouncementBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxAnnouncementBar, [{
    type: Component,
    args: [{
      selector: 'dx-announcement-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'icon', 'iconcolor'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxApp = class DxApp {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxApp.ɵfac = function DxApp_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxApp)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxApp.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxApp,
  selectors: [["dx-app"]],
  inputs: {
    fullscreen: "fullscreen"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxApp_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxApp = __decorate([ProxyCmp({
  inputs: ['fullscreen']
})], DxApp);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxApp, [{
    type: Component,
    args: [{
      selector: 'dx-app',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['fullscreen'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxBreadcrumb = class DxBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['navigateHome', 'navigate']);
  }
};
DxBreadcrumb.ɵfac = function DxBreadcrumb_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxBreadcrumb)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxBreadcrumb.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxBreadcrumb,
  selectors: [["dx-breadcrumb"]],
  outputs: {
    navigateHome: "navigateHome",
    navigate: "navigate"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxBreadcrumb_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxBreadcrumb = __decorate([ProxyCmp({})], DxBreadcrumb);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxBreadcrumb, [{
    type: Component,
    args: [{
      selector: 'dx-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      outputs: ["navigateHome", "navigate"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxBreadcrumbItem = class DxBreadcrumbItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxBreadcrumbItem.ɵfac = function DxBreadcrumbItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxBreadcrumbItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxBreadcrumbItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxBreadcrumbItem,
  selectors: [["dx-breadcrumb-item"]],
  inputs: {
    label: "label",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxBreadcrumbItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxBreadcrumbItem = __decorate([ProxyCmp({
  inputs: ['label', 'value']
})], DxBreadcrumbItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxBreadcrumbItem, [{
    type: Component,
    args: [{
      selector: 'dx-breadcrumb-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['label', 'value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxButton = class DxButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxButton.ɵfac = function DxButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxButton,
  selectors: [["dx-button"]],
  inputs: {
    disabled: "disabled",
    href: "href",
    icon: "icon",
    iconposition: "iconposition",
    label: "label",
    loading: "loading",
    stretch: "stretch",
    target: "target",
    theme: "theme",
    type: "type",
    unfocusable: "unfocusable"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxButton = __decorate([ProxyCmp({
  inputs: ['disabled', 'href', 'icon', 'iconposition', 'label', 'loading', 'stretch', 'target', 'theme', 'type', 'unfocusable'],
  methods: ['focusControl']
})], DxButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxButton, [{
    type: Component,
    args: [{
      selector: 'dx-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'href', 'icon', 'iconposition', 'label', 'loading', 'stretch', 'target', 'theme', 'type', 'unfocusable'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCard = class DxCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['closeCard']);
  }
};
DxCard.ɵfac = function DxCard_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCard.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCard,
  selectors: [["dx-card"]],
  inputs: {
    closeable: "closeable"
  },
  outputs: {
    closeCard: "closeCard"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCard_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCard = __decorate([ProxyCmp({
  inputs: ['closeable']
})], DxCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCard, [{
    type: Component,
    args: [{
      selector: 'dx-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeable'],
      outputs: ["closeCard"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCardCollapsable = class DxCardCollapsable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['openChange']);
  }
};
DxCardCollapsable.ɵfac = function DxCardCollapsable_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCardCollapsable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCardCollapsable.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCardCollapsable,
  selectors: [["dx-card-collapsable"]],
  inputs: {
    chevronvisible: "chevronvisible",
    open: "open"
  },
  outputs: {
    openChange: "openChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCardCollapsable_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCardCollapsable = __decorate([ProxyCmp({
  inputs: ['chevronvisible', 'open']
})], DxCardCollapsable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCardCollapsable, [{
    type: Component,
    args: [{
      selector: 'dx-card-collapsable',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['chevronvisible', 'open'],
      outputs: ["openChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCardContent = class DxCardContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxCardContent.ɵfac = function DxCardContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCardContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCardContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCardContent,
  selectors: [["dx-card-content"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCardContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCardContent = __decorate([ProxyCmp({})], DxCardContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCardContent, [{
    type: Component,
    args: [{
      selector: 'dx-card-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCardHeader = class DxCardHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxCardHeader.ɵfac = function DxCardHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCardHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCardHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCardHeader,
  selectors: [["dx-card-header"]],
  inputs: {
    contentbelow: "contentbelow",
    icon: "icon",
    iconcolor: "iconcolor",
    label: "label",
    lines: "lines",
    sublabel: "sublabel",
    sublabellines: "sublabellines"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCardHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCardHeader = __decorate([ProxyCmp({
  inputs: ['contentbelow', 'icon', 'iconcolor', 'label', 'lines', 'sublabel', 'sublabellines']
})], DxCardHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCardHeader, [{
    type: Component,
    args: [{
      selector: 'dx-card-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentbelow', 'icon', 'iconcolor', 'label', 'lines', 'sublabel', 'sublabellines'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCardImage = class DxCardImage {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxCardImage.ɵfac = function DxCardImage_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCardImage)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCardImage.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCardImage,
  selectors: [["dx-card-image"]],
  inputs: {
    coverheight: "coverheight",
    coverheightmq1: "coverheightmq1",
    coverheightmq2: "coverheightmq2",
    coverheightmq3: "coverheightmq3",
    coverheightmq4: "coverheightmq4",
    coverheightmq5: "coverheightmq5",
    coverposition: "coverposition",
    coverpositionmq1: "coverpositionmq1",
    coverpositionmq2: "coverpositionmq2",
    coverpositionmq3: "coverpositionmq3",
    coverpositionmq4: "coverpositionmq4",
    coverpositionmq5: "coverpositionmq5",
    fit: "fit",
    url: "url"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCardImage_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCardImage = __decorate([ProxyCmp({
  inputs: ['coverheight', 'coverheightmq1', 'coverheightmq2', 'coverheightmq3', 'coverheightmq4', 'coverheightmq5', 'coverposition', 'coverpositionmq1', 'coverpositionmq2', 'coverpositionmq3', 'coverpositionmq4', 'coverpositionmq5', 'fit', 'url']
})], DxCardImage);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCardImage, [{
    type: Component,
    args: [{
      selector: 'dx-card-image',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['coverheight', 'coverheightmq1', 'coverheightmq2', 'coverheightmq3', 'coverheightmq4', 'coverheightmq5', 'coverposition', 'coverpositionmq1', 'coverpositionmq2', 'coverpositionmq3', 'coverpositionmq4', 'coverpositionmq5', 'fit', 'url'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxChartSample = class DxChartSample {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
DxChartSample.ɵfac = function DxChartSample_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxChartSample)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxChartSample.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxChartSample,
  selectors: [["dx-chart-sample"]],
  inputs: {
    color: "color",
    icon: "icon",
    label: "label",
    selectedcolor: "selectedcolor",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxChartSample_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxChartSample = __decorate([ProxyCmp({
  inputs: ['color', 'icon', 'label', 'selectedcolor', 'value'],
  methods: ['toChartSample']
})], DxChartSample);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxChartSample, [{
    type: Component,
    args: [{
      selector: 'dx-chart-sample',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'icon', 'label', 'selectedcolor', 'value'],
      outputs: ["valueChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCheckbox = class DxCheckbox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['checkedChange']);
  }
};
DxCheckbox.ɵfac = function DxCheckbox_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCheckbox,
  selectors: [["dx-checkbox"]],
  inputs: {
    checked: "checked",
    disabled: "disabled",
    error: "error",
    errormessage: "errormessage",
    kisynced: "kisynced",
    label: "label",
    readonly: "readonly",
    required: "required",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue"
  },
  outputs: {
    checkedChange: "checkedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCheckbox = __decorate([ProxyCmp({
  inputs: ['checked', 'disabled', 'error', 'errormessage', 'kisynced', 'label', 'readonly', 'required', 'templateindicatortype', 'templatevalue'],
  methods: ['focusControl']
})], DxCheckbox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCheckbox, [{
    type: Component,
    args: [{
      selector: 'dx-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'error', 'errormessage', 'kisynced', 'label', 'readonly', 'required', 'templateindicatortype', 'templatevalue'],
      outputs: ["checkedChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCheckboxGroup = class DxCheckboxGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxCheckboxGroup.ɵfac = function DxCheckboxGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCheckboxGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCheckboxGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCheckboxGroup,
  selectors: [["dx-checkbox-group"]],
  inputs: {
    disabled: "disabled",
    errormessage: "errormessage",
    kisynced: "kisynced",
    label: "label",
    readonly: "readonly",
    required: "required"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCheckboxGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCheckboxGroup = __decorate([ProxyCmp({
  inputs: ['disabled', 'errormessage', 'kisynced', 'label', 'readonly', 'required']
})], DxCheckboxGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCheckboxGroup, [{
    type: Component,
    args: [{
      selector: 'dx-checkbox-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'errormessage', 'kisynced', 'label', 'readonly', 'required'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCombobox = class DxCombobox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'valueComplete', 'search', 'selectSearchOption', 'inputChange', 'errorFound', 'enterPress']);
  }
};
DxCombobox.ɵfac = function DxCombobox_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCombobox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCombobox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCombobox,
  selectors: [["dx-combobox"]],
  inputs: {
    disabled: "disabled",
    emptysearchtext: "emptysearchtext",
    errormessage: "errormessage",
    kisynced: "kisynced",
    label: "label",
    limitlength: "limitlength",
    loadingtext: "loadingtext",
    maxlistheight: "maxlistheight",
    placeholder: "placeholder",
    presearchconditiontext: "presearchconditiontext",
    presearchlettercount: "presearchlettercount",
    readonly: "readonly",
    required: "required",
    size: "size",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    transformvalue: "transformvalue",
    usagemode: "usagemode",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange",
    valueComplete: "valueComplete",
    search: "search",
    selectSearchOption: "selectSearchOption",
    inputChange: "inputChange",
    errorFound: "errorFound",
    enterPress: "enterPress"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCombobox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCombobox = __decorate([ProxyCmp({
  inputs: ['disabled', 'emptysearchtext', 'errormessage', 'kisynced', 'label', 'limitlength', 'loadingtext', 'maxlistheight', 'placeholder', 'presearchconditiontext', 'presearchlettercount', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'transformvalue', 'usagemode', 'value'],
  methods: ['focusControl', 'reset']
})], DxCombobox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCombobox, [{
    type: Component,
    args: [{
      selector: 'dx-combobox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'emptysearchtext', 'errormessage', 'kisynced', 'label', 'limitlength', 'loadingtext', 'maxlistheight', 'placeholder', 'presearchconditiontext', 'presearchlettercount', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'transformvalue', 'usagemode', 'value'],
      outputs: ["valueChange", "valueComplete", "search", "selectSearchOption", "inputChange", "errorFound", "enterPress"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxContainer = class DxContainer {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxContainer.ɵfac = function DxContainer_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxContainer)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxContainer.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxContainer,
  selectors: [["dx-container"]],
  inputs: {
    color: "color",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxContainer_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxContainer = __decorate([ProxyCmp({
  inputs: ['color', 'type']
})], DxContainer);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxContainer, [{
    type: Component,
    args: [{
      selector: 'dx-container',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'type'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCookieBanner = class DxCookieBanner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['cookiesAccepted']);
  }
};
DxCookieBanner.ɵfac = function DxCookieBanner_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCookieBanner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCookieBanner.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCookieBanner,
  selectors: [["dx-cookie-banner"]],
  inputs: {
    rejectable: "rejectable",
    view: "view",
    visible: "visible"
  },
  outputs: {
    cookiesAccepted: "cookiesAccepted"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCookieBanner_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCookieBanner = __decorate([ProxyCmp({
  inputs: ['rejectable', 'view', 'visible']
})], DxCookieBanner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCookieBanner, [{
    type: Component,
    args: [{
      selector: 'dx-cookie-banner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['rejectable', 'view', 'visible'],
      outputs: ["cookiesAccepted"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxCookieChoice = class DxCookieChoice {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['checkedChange']);
  }
};
DxCookieChoice.ɵfac = function DxCookieChoice_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxCookieChoice)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxCookieChoice.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxCookieChoice,
  selectors: [["dx-cookie-choice"]],
  inputs: {
    checked: "checked",
    label: "label",
    value: "value"
  },
  outputs: {
    checkedChange: "checkedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxCookieChoice_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxCookieChoice = __decorate([ProxyCmp({
  inputs: ['checked', 'label', 'value']
})], DxCookieChoice);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCookieChoice, [{
    type: Component,
    args: [{
      selector: 'dx-cookie-choice',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'label', 'value'],
      outputs: ["checkedChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxDateInput = class DxDateInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'valueComplete', 'errorFound']);
  }
};
DxDateInput.ɵfac = function DxDateInput_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxDateInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxDateInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxDateInput,
  selectors: [["dx-date-input"]],
  inputs: {
    calendarerrormessage: "calendarerrormessage",
    disabled: "disabled",
    errormessage: "errormessage",
    kisynced: "kisynced",
    label: "label",
    max: "max",
    min: "min",
    mode: "mode",
    readonly: "readonly",
    required: "required",
    size: "size",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange",
    valueComplete: "valueComplete",
    errorFound: "errorFound"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxDateInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxDateInput = __decorate([ProxyCmp({
  inputs: ['calendarerrormessage', 'disabled', 'errormessage', 'kisynced', 'label', 'max', 'min', 'mode', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'value'],
  methods: ['focusControl', 'setAll']
})], DxDateInput);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDateInput, [{
    type: Component,
    args: [{
      selector: 'dx-date-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['calendarerrormessage', 'disabled', 'errormessage', 'kisynced', 'label', 'max', 'min', 'mode', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'value'],
      outputs: ["valueChange", "valueComplete", "errorFound"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxDateRange = class DxDateRange {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['rangeErrorFound']);
  }
};
DxDateRange.ɵfac = function DxDateRange_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxDateRange)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxDateRange.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxDateRange,
  selectors: [["dx-date-range"]],
  inputs: {
    layout: "layout",
    max: "max",
    min: "min",
    mode: "mode",
    templatevalue: "templatevalue",
    value: "value"
  },
  outputs: {
    rangeErrorFound: "rangeErrorFound"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxDateRange_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxDateRange = __decorate([ProxyCmp({
  inputs: ['layout', 'max', 'min', 'mode', 'templatevalue', 'value'],
  methods: ['focusControl']
})], DxDateRange);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDateRange, [{
    type: Component,
    args: [{
      selector: 'dx-date-range',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['layout', 'max', 'min', 'mode', 'templatevalue', 'value'],
      outputs: ["rangeErrorFound"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxDateTime = class DxDateTime {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'valueComplete', 'errorFound', 'inputChange', 'inputComplete']);
  }
};
DxDateTime.ɵfac = function DxDateTime_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxDateTime)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxDateTime.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxDateTime,
  selectors: [["dx-date-time"]],
  inputs: {
    disabled: "disabled",
    errormessage: "errormessage",
    hidesegmentlabels: "hidesegmentlabels",
    kisynced: "kisynced",
    label: "label",
    max: "max",
    min: "min",
    readonly: "readonly",
    required: "required",
    size: "size",
    templatevalue: "templatevalue",
    validateformat: "validateformat",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange",
    valueComplete: "valueComplete",
    errorFound: "errorFound",
    inputChange: "inputChange",
    inputComplete: "inputComplete"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxDateTime_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxDateTime = __decorate([ProxyCmp({
  inputs: ['disabled', 'errormessage', 'hidesegmentlabels', 'kisynced', 'label', 'max', 'min', 'readonly', 'required', 'size', 'templatevalue', 'validateformat', 'value'],
  methods: ['setDay', 'setMonth', 'setYear', 'setHour', 'setMinute', 'setAll', 'focusControl']
})], DxDateTime);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDateTime, [{
    type: Component,
    args: [{
      selector: 'dx-date-time',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'errormessage', 'hidesegmentlabels', 'kisynced', 'label', 'max', 'min', 'readonly', 'required', 'size', 'templatevalue', 'validateformat', 'value'],
      outputs: ["valueChange", "valueComplete", "errorFound", "inputChange", "inputComplete"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxDateTimeRange = class DxDateTimeRange {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['rangeErrorFound']);
  }
};
DxDateTimeRange.ɵfac = function DxDateTimeRange_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxDateTimeRange)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxDateTimeRange.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxDateTimeRange,
  selectors: [["dx-date-time-range"]],
  inputs: {
    layout: "layout",
    max: "max",
    min: "min",
    value: "value"
  },
  outputs: {
    rangeErrorFound: "rangeErrorFound"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxDateTimeRange_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxDateTimeRange = __decorate([ProxyCmp({
  inputs: ['layout', 'max', 'min', 'value'],
  methods: ['focusControl']
})], DxDateTimeRange);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDateTimeRange, [{
    type: Component,
    args: [{
      selector: 'dx-date-time-range',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['layout', 'max', 'min', 'value'],
      outputs: ["rangeErrorFound"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxDateTimeSegment = class DxDateTimeSegment {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxDateTimeSegment.ɵfac = function DxDateTimeSegment_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxDateTimeSegment)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxDateTimeSegment.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxDateTimeSegment,
  selectors: [["dx-date-time-segment"]],
  inputs: {
    error: "error",
    required: "required",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxDateTimeSegment_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxDateTimeSegment = __decorate([ProxyCmp({
  inputs: ['error', 'required', 'templateindicatortype', 'templatevalue', 'type'],
  methods: ['focusControl']
})], DxDateTimeSegment);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDateTimeSegment, [{
    type: Component,
    args: [{
      selector: 'dx-date-time-segment',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['error', 'required', 'templateindicatortype', 'templatevalue', 'type'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxDonutChart = class DxDonutChart {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['segmentSelect']);
  }
};
DxDonutChart.ɵfac = function DxDonutChart_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxDonutChart)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxDonutChart.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxDonutChart,
  selectors: [["dx-donut-chart"]],
  inputs: {
    colorscheme: "colorscheme",
    hasinteraction: "hasinteraction",
    label: "label",
    labelsize: "labelsize",
    minsegmentpercentage: "minsegmentpercentage",
    selected: "selected",
    showsegmentlabels: "showsegmentlabels",
    sublabel: "sublabel",
    useselectionopacity: "useselectionopacity"
  },
  outputs: {
    segmentSelect: "segmentSelect"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxDonutChart_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxDonutChart = __decorate([ProxyCmp({
  inputs: ['colorscheme', 'hasinteraction', 'label', 'labelsize', 'minsegmentpercentage', 'selected', 'showsegmentlabels', 'sublabel', 'useselectionopacity'],
  methods: ['update']
})], DxDonutChart);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDonutChart, [{
    type: Component,
    args: [{
      selector: 'dx-donut-chart',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['colorscheme', 'hasinteraction', 'label', 'labelsize', 'minsegmentpercentage', 'selected', 'showsegmentlabels', 'sublabel', 'useselectionopacity'],
      outputs: ["segmentSelect"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxDropdown = class DxDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'valueComplete']);
  }
};
DxDropdown.ɵfac = function DxDropdown_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxDropdown.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxDropdown,
  selectors: [["dx-dropdown"]],
  inputs: {
    clearable: "clearable",
    disabled: "disabled",
    elseoption: "elseoption",
    elseoptionbuttonlabel: "elseoptionbuttonlabel",
    elseoptioninputlabel: "elseoptioninputlabel",
    elseoptionselected: "elseoptionselected",
    errormessage: "errormessage",
    icon: "icon",
    keeplistitemtypes: "keeplistitemtypes",
    kisynced: "kisynced",
    label: "label",
    maxlistheight: "maxlistheight",
    placeholder: "placeholder",
    popupposition: "popupposition",
    readonly: "readonly",
    required: "required",
    size: "size",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    textalign: "textalign",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange",
    valueComplete: "valueComplete"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxDropdown_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxDropdown = __decorate([ProxyCmp({
  inputs: ['clearable', 'disabled', 'elseoption', 'elseoptionbuttonlabel', 'elseoptioninputlabel', 'elseoptionselected', 'errormessage', 'icon', 'keeplistitemtypes', 'kisynced', 'label', 'maxlistheight', 'placeholder', 'popupposition', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'textalign', 'value'],
  methods: ['focusControl']
})], DxDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDropdown, [{
    type: Component,
    args: [{
      selector: 'dx-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['clearable', 'disabled', 'elseoption', 'elseoptionbuttonlabel', 'elseoptioninputlabel', 'elseoptionselected', 'errormessage', 'icon', 'keeplistitemtypes', 'kisynced', 'label', 'maxlistheight', 'placeholder', 'popupposition', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'textalign', 'value'],
      outputs: ["valueChange", "valueComplete"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxDropdownOption = class DxDropdownOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxDropdownOption.ɵfac = function DxDropdownOption_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxDropdownOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxDropdownOption.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxDropdownOption,
  selectors: [["dx-dropdown-option"]],
  inputs: {
    disabled: "disabled",
    icon: "icon",
    label: "label",
    prelabel: "prelabel",
    sublabel: "sublabel",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxDropdownOption_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxDropdownOption = __decorate([ProxyCmp({
  inputs: ['disabled', 'icon', 'label', 'prelabel', 'sublabel', 'value']
})], DxDropdownOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDropdownOption, [{
    type: Component,
    args: [{
      selector: 'dx-dropdown-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'icon', 'label', 'prelabel', 'sublabel', 'value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxErrorMessage = class DxErrorMessage {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxErrorMessage.ɵfac = function DxErrorMessage_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxErrorMessage)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxErrorMessage.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxErrorMessage,
  selectors: [["dx-error-message"]],
  inputs: {
    lines: "lines",
    popoverdisplay: "popoverdisplay"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxErrorMessage_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxErrorMessage = __decorate([ProxyCmp({
  inputs: ['lines', 'popoverdisplay']
})], DxErrorMessage);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxErrorMessage, [{
    type: Component,
    args: [{
      selector: 'dx-error-message',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['lines', 'popoverdisplay'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxErrorPage = class DxErrorPage {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['action']);
  }
};
DxErrorPage.ɵfac = function DxErrorPage_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxErrorPage)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxErrorPage.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxErrorPage,
  selectors: [["dx-error-page"]],
  inputs: {
    actiondescription: "actiondescription",
    actionlabel: "actionlabel",
    description: "description",
    reason: "reason"
  },
  outputs: {
    action: "action"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxErrorPage_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxErrorPage = __decorate([ProxyCmp({
  inputs: ['actiondescription', 'actionlabel', 'description', 'reason']
})], DxErrorPage);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxErrorPage, [{
    type: Component,
    args: [{
      selector: 'dx-error-page',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['actiondescription', 'actionlabel', 'description', 'reason'],
      outputs: ["action"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxExpanded = class DxExpanded {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxExpanded.ɵfac = function DxExpanded_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxExpanded)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxExpanded.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxExpanded,
  selectors: [["dx-expanded"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxExpanded_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxExpanded = __decorate([ProxyCmp({})], DxExpanded);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxExpanded, [{
    type: Component,
    args: [{
      selector: 'dx-expanded',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxFdOutlineBox = class DxFdOutlineBox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxFdOutlineBox.ɵfac = function DxFdOutlineBox_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxFdOutlineBox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxFdOutlineBox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxFdOutlineBox,
  selectors: [["dx-fd-outline-box"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxFdOutlineBox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxFdOutlineBox = __decorate([ProxyCmp({})], DxFdOutlineBox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxFdOutlineBox, [{
    type: Component,
    args: [{
      selector: 'dx-fd-outline-box',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxFdSeparator = class DxFdSeparator {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxFdSeparator.ɵfac = function DxFdSeparator_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxFdSeparator)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxFdSeparator.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxFdSeparator,
  selectors: [["dx-fd-separator"]],
  inputs: {
    fromcolor: "fromcolor",
    tocolor: "tocolor"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxFdSeparator_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxFdSeparator = __decorate([ProxyCmp({
  inputs: ['fromcolor', 'tocolor']
})], DxFdSeparator);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxFdSeparator, [{
    type: Component,
    args: [{
      selector: 'dx-fd-separator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['fromcolor', 'tocolor'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxFooter = class DxFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxFooter.ɵfac = function DxFooter_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxFooter.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxFooter,
  selectors: [["dx-footer"]],
  inputs: {
    fromcolor: "fromcolor"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxFooter_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxFooter = __decorate([ProxyCmp({
  inputs: ['fromcolor']
})], DxFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxFooter, [{
    type: Component,
    args: [{
      selector: 'dx-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['fromcolor'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxFormWrapper = class DxFormWrapper {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxFormWrapper.ɵfac = function DxFormWrapper_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxFormWrapper)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxFormWrapper.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxFormWrapper,
  selectors: [["dx-form-wrapper"]],
  inputs: {
    errormessage: "errormessage",
    kisynced: "kisynced",
    label: "label",
    required: "required",
    rowgap: "rowgap"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxFormWrapper_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxFormWrapper = __decorate([ProxyCmp({
  inputs: ['errormessage', 'kisynced', 'label', 'required', 'rowgap']
})], DxFormWrapper);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxFormWrapper, [{
    type: Component,
    args: [{
      selector: 'dx-form-wrapper',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['errormessage', 'kisynced', 'label', 'required', 'rowgap'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxGoldenStage = class DxGoldenStage {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxGoldenStage.ɵfac = function DxGoldenStage_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxGoldenStage)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxGoldenStage.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxGoldenStage,
  selectors: [["dx-golden-stage"]],
  inputs: {
    alignment: "alignment",
    backgroundcolor: "backgroundcolor",
    contentoffsety: "contentoffsety",
    fullscreen: "fullscreen",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxGoldenStage_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxGoldenStage = __decorate([ProxyCmp({
  inputs: ['alignment', 'backgroundcolor', 'contentoffsety', 'fullscreen', 'size']
})], DxGoldenStage);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxGoldenStage, [{
    type: Component,
    args: [{
      selector: 'dx-golden-stage',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignment', 'backgroundcolor', 'contentoffsety', 'fullscreen', 'size'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxGrid = class DxGrid {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxGrid.ɵfac = function DxGrid_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxGrid)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxGrid.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxGrid,
  selectors: [["dx-grid"]],
  inputs: {
    base: "base",
    mq1: "mq1",
    mq2: "mq2",
    mq3: "mq3",
    mq4: "mq4",
    mq5: "mq5",
    rowgap: "rowgap"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxGrid_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxGrid = __decorate([ProxyCmp({
  inputs: ['base', 'mq1', 'mq2', 'mq3', 'mq4', 'mq5', 'rowgap']
})], DxGrid);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxGrid, [{
    type: Component,
    args: [{
      selector: 'dx-grid',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['base', 'mq1', 'mq2', 'mq3', 'mq4', 'mq5', 'rowgap'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeader = class DxHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['navigateHome', 'headerItemVisibilityChange', 'sidebarStateChange']);
  }
};
DxHeader.ɵfac = function DxHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeader,
  selectors: [["dx-header"]],
  inputs: {
    appname: "appname",
    brand: "brand",
    hidemainnavigation: "hidemainnavigation",
    logo: "logo",
    showannouncement: "showannouncement",
    theme: "theme"
  },
  outputs: {
    navigateHome: "navigateHome",
    headerItemVisibilityChange: "headerItemVisibilityChange",
    sidebarStateChange: "sidebarStateChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeader = __decorate([ProxyCmp({
  inputs: ['appname', 'brand', 'hidemainnavigation', 'logo', 'showannouncement', 'theme'],
  methods: ['openSidebar', 'closeSidebar']
})], DxHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeader, [{
    type: Component,
    args: [{
      selector: 'dx-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['appname', 'brand', 'hidemainnavigation', 'logo', 'showannouncement', 'theme'],
      outputs: ["navigateHome", "headerItemVisibilityChange", "sidebarStateChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeaderAppFavorites = class DxHeaderAppFavorites {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['menuInteraction', 'deleteApp', 'sortApps', 'addApp', 'editApps', 'launchApp']);
  }
};
DxHeaderAppFavorites.ɵfac = function DxHeaderAppFavorites_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeaderAppFavorites)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeaderAppFavorites.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeaderAppFavorites,
  selectors: [["dx-header-app-favorites"]],
  inputs: {
    notifications: "notifications",
    notificationstheme: "notificationstheme",
    priority: "priority"
  },
  outputs: {
    menuInteraction: "menuInteraction",
    deleteApp: "deleteApp",
    sortApps: "sortApps",
    addApp: "addApp",
    editApps: "editApps",
    launchApp: "launchApp"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeaderAppFavorites_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeaderAppFavorites = __decorate([ProxyCmp({
  inputs: ['notifications', 'notificationstheme', 'priority'],
  methods: ['openSidebar', 'closeSidebar']
})], DxHeaderAppFavorites);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeaderAppFavorites, [{
    type: Component,
    args: [{
      selector: 'dx-header-app-favorites',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['notifications', 'notificationstheme', 'priority'],
      outputs: ["menuInteraction", "deleteApp", "sortApps", "addApp", "editApps", "launchApp"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeaderAppFavoritesApp = class DxHeaderAppFavoritesApp {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxHeaderAppFavoritesApp.ɵfac = function DxHeaderAppFavoritesApp_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeaderAppFavoritesApp)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeaderAppFavoritesApp.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeaderAppFavoritesApp,
  selectors: [["dx-header-app-favorites-app"]],
  inputs: {
    appid: "appid",
    appname: "appname",
    icon: "icon"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeaderAppFavoritesApp_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeaderAppFavoritesApp = __decorate([ProxyCmp({
  inputs: ['appid', 'appname', 'icon'],
  methods: ['toAppFavoritesApp']
})], DxHeaderAppFavoritesApp);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeaderAppFavoritesApp, [{
    type: Component,
    args: [{
      selector: 'dx-header-app-favorites-app',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['appid', 'appname', 'icon'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeaderMenuCustom = class DxHeaderMenuCustom {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxHeaderMenuCustom.ɵfac = function DxHeaderMenuCustom_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeaderMenuCustom)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeaderMenuCustom.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeaderMenuCustom,
  selectors: [["dx-header-menu-custom"]],
  inputs: {
    priority: "priority"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeaderMenuCustom_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeaderMenuCustom = __decorate([ProxyCmp({
  inputs: ['priority']
})], DxHeaderMenuCustom);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeaderMenuCustom, [{
    type: Component,
    args: [{
      selector: 'dx-header-menu-custom',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['priority'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeaderMenuGroup = class DxHeaderMenuGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxHeaderMenuGroup.ɵfac = function DxHeaderMenuGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeaderMenuGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeaderMenuGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeaderMenuGroup,
  selectors: [["dx-header-menu-group"]],
  inputs: {
    priority: "priority"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeaderMenuGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeaderMenuGroup = __decorate([ProxyCmp({
  inputs: ['priority']
})], DxHeaderMenuGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeaderMenuGroup, [{
    type: Component,
    args: [{
      selector: 'dx-header-menu-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['priority'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeaderMenuItem = class DxHeaderMenuItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['menuInteraction']);
  }
};
DxHeaderMenuItem.ɵfac = function DxHeaderMenuItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeaderMenuItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeaderMenuItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeaderMenuItem,
  selectors: [["dx-header-menu-item"]],
  inputs: {
    connectedslot: "connectedslot",
    icon: "icon",
    notifications: "notifications",
    notificationstheme: "notificationstheme",
    priority: "priority"
  },
  outputs: {
    menuInteraction: "menuInteraction"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeaderMenuItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeaderMenuItem = __decorate([ProxyCmp({
  inputs: ['connectedslot', 'icon', 'notifications', 'notificationstheme', 'priority'],
  methods: ['openSidebar', 'closeSidebar']
})], DxHeaderMenuItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeaderMenuItem, [{
    type: Component,
    args: [{
      selector: 'dx-header-menu-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['connectedslot', 'icon', 'notifications', 'notificationstheme', 'priority'],
      outputs: ["menuInteraction"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeaderMenuLink = class DxHeaderMenuLink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['menuInteraction']);
  }
};
DxHeaderMenuLink.ɵfac = function DxHeaderMenuLink_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeaderMenuLink)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeaderMenuLink.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeaderMenuLink,
  selectors: [["dx-header-menu-link"]],
  inputs: {
    connectednavigationvalue: "connectednavigationvalue",
    label: "label",
    priority: "priority",
    selected: "selected"
  },
  outputs: {
    menuInteraction: "menuInteraction"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeaderMenuLink_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeaderMenuLink = __decorate([ProxyCmp({
  inputs: ['connectednavigationvalue', 'label', 'priority', 'selected']
})], DxHeaderMenuLink);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeaderMenuLink, [{
    type: Component,
    args: [{
      selector: 'dx-header-menu-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['connectednavigationvalue', 'label', 'priority', 'selected'],
      outputs: ["menuInteraction"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeaderNavigation = class DxHeaderNavigation {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['menuInteraction', 'navigate']);
  }
};
DxHeaderNavigation.ɵfac = function DxHeaderNavigation_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeaderNavigation)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeaderNavigation.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeaderNavigation,
  selectors: [["dx-header-navigation"]],
  inputs: {
    autoclose: "autoclose",
    notification: "notification",
    notificationstheme: "notificationstheme",
    selectedvalue: "selectedvalue"
  },
  outputs: {
    menuInteraction: "menuInteraction",
    navigate: "navigate"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeaderNavigation_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeaderNavigation = __decorate([ProxyCmp({
  inputs: ['autoclose', 'notification', 'notificationstheme', 'selectedvalue'],
  methods: ['openSidebar', 'closeSidebar']
})], DxHeaderNavigation);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeaderNavigation, [{
    type: Component,
    args: [{
      selector: 'dx-header-navigation',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoclose', 'notification', 'notificationstheme', 'selectedvalue'],
      outputs: ["menuInteraction", "navigate"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeaderNavigationItem = class DxHeaderNavigationItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['interaction']);
  }
};
DxHeaderNavigationItem.ɵfac = function DxHeaderNavigationItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeaderNavigationItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeaderNavigationItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeaderNavigationItem,
  selectors: [["dx-header-navigation-item"]],
  inputs: {
    label: "label",
    size: "size",
    value: "value"
  },
  outputs: {
    interaction: "interaction"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeaderNavigationItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeaderNavigationItem = __decorate([ProxyCmp({
  inputs: ['label', 'size', 'value']
})], DxHeaderNavigationItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeaderNavigationItem, [{
    type: Component,
    args: [{
      selector: 'dx-header-navigation-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['label', 'size', 'value'],
      outputs: ["interaction"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeaderUser = class DxHeaderUser {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['signOut', 'accountInteraction', 'changeRoleInteraction', 'menuInteraction']);
  }
};
DxHeaderUser.ɵfac = function DxHeaderUser_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeaderUser)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeaderUser.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeaderUser,
  selectors: [["dx-header-user"]],
  inputs: {
    accountavailable: "accountavailable",
    imageurl: "imageurl",
    name: "name",
    notifications: "notifications",
    notificationstheme: "notificationstheme",
    priority: "priority",
    showchangerolebutton: "showchangerolebutton"
  },
  outputs: {
    signOut: "signOut",
    accountInteraction: "accountInteraction",
    changeRoleInteraction: "changeRoleInteraction",
    menuInteraction: "menuInteraction"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeaderUser_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeaderUser = __decorate([ProxyCmp({
  inputs: ['accountavailable', 'imageurl', 'name', 'notifications', 'notificationstheme', 'priority', 'showchangerolebutton'],
  methods: ['openSidebar', 'closeSidebar']
})], DxHeaderUser);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeaderUser, [{
    type: Component,
    args: [{
      selector: 'dx-header-user',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['accountavailable', 'imageurl', 'name', 'notifications', 'notificationstheme', 'priority', 'showchangerolebutton'],
      outputs: ["signOut", "accountInteraction", "changeRoleInteraction", "menuInteraction"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxHeroImage = class DxHeroImage {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxHeroImage.ɵfac = function DxHeroImage_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxHeroImage)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxHeroImage.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxHeroImage,
  selectors: [["dx-hero-image"]],
  inputs: {
    coverposition: "coverposition",
    mq1: "mq1",
    mq2: "mq2",
    mq3: "mq3",
    mq4: "mq4",
    mq5: "mq5"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxHeroImage_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxHeroImage = __decorate([ProxyCmp({
  inputs: ['coverposition', 'mq1', 'mq2', 'mq3', 'mq4', 'mq5']
})], DxHeroImage);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxHeroImage, [{
    type: Component,
    args: [{
      selector: 'dx-hero-image',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['coverposition', 'mq1', 'mq2', 'mq3', 'mq4', 'mq5'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxIcon = class DxIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxIcon.ɵfac = function DxIcon_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxIcon.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxIcon,
  selectors: [["dx-icon"]],
  inputs: {
    color: "color",
    icon: "icon",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxIcon_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxIcon = __decorate([ProxyCmp({
  inputs: ['color', 'icon', 'size']
})], DxIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxIcon, [{
    type: Component,
    args: [{
      selector: 'dx-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'icon', 'size'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxInfo = class DxInfo {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxInfo.ɵfac = function DxInfo_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxInfo)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxInfo.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxInfo,
  selectors: [["dx-info"]],
  inputs: {
    disabled: "disabled",
    hidebutton: "hidebutton",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxInfo_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxInfo = __decorate([ProxyCmp({
  inputs: ['disabled', 'hidebutton', 'size'],
  methods: ['setModal', 'focusControl']
})], DxInfo);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxInfo, [{
    type: Component,
    args: [{
      selector: 'dx-info',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'hidebutton', 'size'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxLegend = class DxLegend {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxLegend.ɵfac = function DxLegend_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxLegend)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxLegend.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxLegend,
  selectors: [["dx-legend"]],
  inputs: {
    checkhint: "checkhint",
    errorhint: "errorhint",
    kisynchint: "kisynchint",
    ocrhint: "ocrhint",
    requiredhint: "requiredhint",
    templatehint: "templatehint"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxLegend_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxLegend = __decorate([ProxyCmp({
  inputs: ['checkhint', 'errorhint', 'kisynchint', 'ocrhint', 'requiredhint', 'templatehint']
})], DxLegend);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxLegend, [{
    type: Component,
    args: [{
      selector: 'dx-legend',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checkhint', 'errorhint', 'kisynchint', 'ocrhint', 'requiredhint', 'templatehint'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxLink = class DxLink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxLink.ɵfac = function DxLink_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxLink)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxLink.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxLink,
  selectors: [["dx-link"]],
  inputs: {
    href: "href",
    target: "target"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxLink_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxLink = __decorate([ProxyCmp({
  inputs: ['href', 'target']
})], DxLink);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxLink, [{
    type: Component,
    args: [{
      selector: 'dx-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['href', 'target'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxList = class DxList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxList.ɵfac = function DxList_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxList,
  selectors: [["dx-list"]],
  inputs: {
    contentbelow: "contentbelow",
    contentheight: "contentheight",
    contentwidth: "contentwidth",
    size: "size",
    width: "width"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxList = __decorate([ProxyCmp({
  inputs: ['contentbelow', 'contentheight', 'contentwidth', 'size', 'width']
})], DxList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxList, [{
    type: Component,
    args: [{
      selector: 'dx-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentbelow', 'contentheight', 'contentwidth', 'size', 'width'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxListItem = class DxListItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
DxListItem.ɵfac = function DxListItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxListItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxListItem,
  selectors: [["dx-list-item"]],
  inputs: {
    actionindicator: "actionindicator",
    contentenabled: "contentenabled",
    disabled: "disabled",
    icon: "icon",
    iconcolor: "iconcolor",
    label: "label",
    labelwordbreak: "labelwordbreak",
    overflowtheme: "overflowtheme",
    prelabel: "prelabel",
    prelabelwordbreak: "prelabelwordbreak",
    selected: "selected",
    selectionmode: "selectionmode",
    sublabel: "sublabel",
    sublabelwordbreak: "sublabelwordbreak",
    templatevalue: "templatevalue",
    textalign: "textalign",
    trailingicon: "trailingicon",
    type: "type",
    unread: "unread",
    value: "value"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxListItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxListItem = __decorate([ProxyCmp({
  inputs: ['actionindicator', 'contentenabled', 'disabled', 'icon', 'iconcolor', 'label', 'labelwordbreak', 'overflowtheme', 'prelabel', 'prelabelwordbreak', 'selected', 'selectionmode', 'sublabel', 'sublabelwordbreak', 'templatevalue', 'textalign', 'trailingicon', 'type', 'unread', 'value'],
  methods: ['focusControl']
})], DxListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxListItem, [{
    type: Component,
    args: [{
      selector: 'dx-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['actionindicator', 'contentenabled', 'disabled', 'icon', 'iconcolor', 'label', 'labelwordbreak', 'overflowtheme', 'prelabel', 'prelabelwordbreak', 'selected', 'selectionmode', 'sublabel', 'sublabelwordbreak', 'templatevalue', 'textalign', 'trailingicon', 'type', 'unread', 'value'],
      outputs: ["selectedChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxListSection = class DxListSection {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxListSection.ɵfac = function DxListSection_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxListSection)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxListSection.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxListSection,
  selectors: [["dx-list-section"]],
  inputs: {
    label: "label"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxListSection_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxListSection = __decorate([ProxyCmp({
  inputs: ['label']
})], DxListSection);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxListSection, [{
    type: Component,
    args: [{
      selector: 'dx-list-section',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['label'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxLoadingIndicator = class DxLoadingIndicator {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxLoadingIndicator.ɵfac = function DxLoadingIndicator_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxLoadingIndicator)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxLoadingIndicator.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxLoadingIndicator,
  selectors: [["dx-loading-indicator"]],
  inputs: {
    label: "label",
    labelalign: "labelalign",
    mode: "mode",
    percent: "percent",
    size: "size",
    theme: "theme"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxLoadingIndicator_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxLoadingIndicator = __decorate([ProxyCmp({
  inputs: ['label', 'labelalign', 'mode', 'percent', 'size', 'theme']
})], DxLoadingIndicator);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxLoadingIndicator, [{
    type: Component,
    args: [{
      selector: 'dx-loading-indicator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['label', 'labelalign', 'mode', 'percent', 'size', 'theme'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxLoadingIndicatorOverlay = class DxLoadingIndicatorOverlay {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxLoadingIndicatorOverlay.ɵfac = function DxLoadingIndicatorOverlay_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxLoadingIndicatorOverlay)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxLoadingIndicatorOverlay.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxLoadingIndicatorOverlay,
  selectors: [["dx-loading-indicator-overlay"]],
  inputs: {
    type: "type",
    visible: "visible"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxLoadingIndicatorOverlay_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxLoadingIndicatorOverlay = __decorate([ProxyCmp({
  inputs: ['type', 'visible']
})], DxLoadingIndicatorOverlay);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxLoadingIndicatorOverlay, [{
    type: Component,
    args: [{
      selector: 'dx-loading-indicator-overlay',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['type', 'visible'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxLogo = class DxLogo {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxLogo.ɵfac = function DxLogo_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxLogo)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxLogo.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxLogo,
  selectors: [["dx-logo"]],
  inputs: {
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxLogo_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxLogo = __decorate([ProxyCmp({
  inputs: ['type']
})], DxLogo);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxLogo, [{
    type: Component,
    args: [{
      selector: 'dx-logo',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['type'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxMediaQueryDisplay = class DxMediaQueryDisplay {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxMediaQueryDisplay.ɵfac = function DxMediaQueryDisplay_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxMediaQueryDisplay)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxMediaQueryDisplay.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxMediaQueryDisplay,
  selectors: [["dx-media-query-display"]],
  inputs: {
    theme: "theme"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxMediaQueryDisplay_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxMediaQueryDisplay = __decorate([ProxyCmp({
  inputs: ['theme']
})], DxMediaQueryDisplay);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxMediaQueryDisplay, [{
    type: Component,
    args: [{
      selector: 'dx-media-query-display',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['theme'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxMediaQueryValue = class DxMediaQueryValue {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxMediaQueryValue.ɵfac = function DxMediaQueryValue_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxMediaQueryValue)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxMediaQueryValue.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxMediaQueryValue,
  selectors: [["dx-media-query-value"]],
  inputs: {
    mq1: "mq1",
    mq2: "mq2",
    mq3: "mq3",
    mq4: "mq4",
    mq5: "mq5",
    property: "property"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxMediaQueryValue_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxMediaQueryValue = __decorate([ProxyCmp({
  inputs: ['mq1', 'mq2', 'mq3', 'mq4', 'mq5', 'property']
})], DxMediaQueryValue);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxMediaQueryValue, [{
    type: Component,
    args: [{
      selector: 'dx-media-query-value',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['mq1', 'mq2', 'mq3', 'mq4', 'mq5', 'property'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxModal = class DxModal {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['modalOpened', 'modalClosed']);
  }
};
DxModal.ɵfac = function DxModal_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxModal)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxModal.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxModal,
  selectors: [["dx-modal"]],
  inputs: {
    height: "height",
    label: "label",
    visible: "visible",
    width: "width"
  },
  outputs: {
    modalOpened: "modalOpened",
    modalClosed: "modalClosed"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxModal_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxModal = __decorate([ProxyCmp({
  inputs: ['height', 'label', 'visible', 'width']
})], DxModal);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxModal, [{
    type: Component,
    args: [{
      selector: 'dx-modal',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['height', 'label', 'visible', 'width'],
      outputs: ["modalOpened", "modalClosed"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxMultiDropdown = class DxMultiDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectionComplete']);
  }
};
DxMultiDropdown.ɵfac = function DxMultiDropdown_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxMultiDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxMultiDropdown.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxMultiDropdown,
  selectors: [["dx-multi-dropdown"]],
  inputs: {
    disabled: "disabled",
    errormessage: "errormessage",
    icon: "icon",
    keeplistitemtypes: "keeplistitemtypes",
    kisynced: "kisynced",
    label: "label",
    maxlistheight: "maxlistheight",
    placeholder: "placeholder",
    popupposition: "popupposition",
    readonly: "readonly",
    required: "required",
    selectionlabel: "selectionlabel",
    size: "size",
    templateindicatortype: "templateindicatortype",
    textalign: "textalign"
  },
  outputs: {
    selectionComplete: "selectionComplete"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxMultiDropdown_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxMultiDropdown = __decorate([ProxyCmp({
  inputs: ['disabled', 'errormessage', 'icon', 'keeplistitemtypes', 'kisynced', 'label', 'maxlistheight', 'placeholder', 'popupposition', 'readonly', 'required', 'selectionlabel', 'size', 'templateindicatortype', 'textalign'],
  methods: ['focusControl']
})], DxMultiDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxMultiDropdown, [{
    type: Component,
    args: [{
      selector: 'dx-multi-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'errormessage', 'icon', 'keeplistitemtypes', 'kisynced', 'label', 'maxlistheight', 'placeholder', 'popupposition', 'readonly', 'required', 'selectionlabel', 'size', 'templateindicatortype', 'textalign'],
      outputs: ["selectionComplete"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxMultiDropdownOption = class DxMultiDropdownOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
DxMultiDropdownOption.ɵfac = function DxMultiDropdownOption_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxMultiDropdownOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxMultiDropdownOption.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxMultiDropdownOption,
  selectors: [["dx-multi-dropdown-option"]],
  inputs: {
    disabled: "disabled",
    icon: "icon",
    label: "label",
    prelabel: "prelabel",
    selected: "selected",
    sublabel: "sublabel",
    templatevalue: "templatevalue"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxMultiDropdownOption_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxMultiDropdownOption = __decorate([ProxyCmp({
  inputs: ['disabled', 'icon', 'label', 'prelabel', 'selected', 'sublabel', 'templatevalue']
})], DxMultiDropdownOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxMultiDropdownOption, [{
    type: Component,
    args: [{
      selector: 'dx-multi-dropdown-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'icon', 'label', 'prelabel', 'selected', 'sublabel', 'templatevalue'],
      outputs: ["selectedChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxMultiSelect = class DxMultiSelect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxMultiSelect.ɵfac = function DxMultiSelect_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxMultiSelect)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxMultiSelect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxMultiSelect,
  selectors: [["dx-multi-select"]],
  inputs: {
    columns: "columns",
    disabled: "disabled",
    errormessage: "errormessage",
    iconsize: "iconsize",
    kisynced: "kisynced",
    label: "label",
    maxselections: "maxselections",
    readonly: "readonly",
    required: "required",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxMultiSelect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxMultiSelect = __decorate([ProxyCmp({
  inputs: ['columns', 'disabled', 'errormessage', 'iconsize', 'kisynced', 'label', 'maxselections', 'readonly', 'required', 'size'],
  methods: ['focusControl']
})], DxMultiSelect);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxMultiSelect, [{
    type: Component,
    args: [{
      selector: 'dx-multi-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['columns', 'disabled', 'errormessage', 'iconsize', 'kisynced', 'label', 'maxselections', 'readonly', 'required', 'size'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxMultiSelectItem = class DxMultiSelectItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
DxMultiSelectItem.ɵfac = function DxMultiSelectItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxMultiSelectItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxMultiSelectItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxMultiSelectItem,
  selectors: [["dx-multi-select-item"]],
  inputs: {
    disabled: "disabled",
    error: "error",
    icon: "icon",
    label: "label",
    readonly: "readonly",
    selected: "selected",
    sublabel: "sublabel",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxMultiSelectItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxMultiSelectItem = __decorate([ProxyCmp({
  inputs: ['disabled', 'error', 'icon', 'label', 'readonly', 'selected', 'sublabel', 'templateindicatortype', 'templatevalue'],
  methods: ['focusControl']
})], DxMultiSelectItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxMultiSelectItem, [{
    type: Component,
    args: [{
      selector: 'dx-multi-select-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'error', 'icon', 'label', 'readonly', 'selected', 'sublabel', 'templateindicatortype', 'templatevalue'],
      outputs: ["selectedChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxNotificationBanner = class DxNotificationBanner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['closeNotification']);
  }
};
DxNotificationBanner.ɵfac = function DxNotificationBanner_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxNotificationBanner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxNotificationBanner.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxNotificationBanner,
  selectors: [["dx-notification-banner"]],
  inputs: {
    background: "background",
    closeable: "closeable",
    padding: "padding",
    state: "state",
    type: "type",
    visible: "visible"
  },
  outputs: {
    closeNotification: "closeNotification"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxNotificationBanner_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxNotificationBanner = __decorate([ProxyCmp({
  inputs: ['background', 'closeable', 'padding', 'state', 'type', 'visible']
})], DxNotificationBanner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxNotificationBanner, [{
    type: Component,
    args: [{
      selector: 'dx-notification-banner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['background', 'closeable', 'padding', 'state', 'type', 'visible'],
      outputs: ["closeNotification"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxNotificationBar = class DxNotificationBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['closeNotification']);
  }
};
DxNotificationBar.ɵfac = function DxNotificationBar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxNotificationBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxNotificationBar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxNotificationBar,
  selectors: [["dx-notification-bar"]],
  inputs: {
    visible: "visible"
  },
  outputs: {
    closeNotification: "closeNotification"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxNotificationBar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxNotificationBar = __decorate([ProxyCmp({
  inputs: ['visible']
})], DxNotificationBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxNotificationBar, [{
    type: Component,
    args: [{
      selector: 'dx-notification-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['visible'],
      outputs: ["closeNotification"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxNotificationToast = class DxNotificationToast {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['closeNotification']);
  }
};
DxNotificationToast.ɵfac = function DxNotificationToast_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxNotificationToast)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxNotificationToast.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxNotificationToast,
  selectors: [["dx-notification-toast"]],
  inputs: {
    closeafter: "closeafter",
    icon: "icon",
    iconcolor: "iconcolor",
    subcontent: "subcontent",
    titlestring: "titlestring",
    type: "type",
    visible: "visible"
  },
  outputs: {
    closeNotification: "closeNotification"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxNotificationToast_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxNotificationToast = __decorate([ProxyCmp({
  inputs: ['closeafter', 'icon', 'iconcolor', 'subcontent', 'titlestring', 'type', 'visible']
})], DxNotificationToast);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxNotificationToast, [{
    type: Component,
    args: [{
      selector: 'dx-notification-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeafter', 'icon', 'iconcolor', 'subcontent', 'titlestring', 'type', 'visible'],
      outputs: ["closeNotification"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxNumberInput = class DxNumberInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'valueComplete', 'errorFound']);
  }
};
DxNumberInput.ɵfac = function DxNumberInput_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxNumberInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxNumberInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxNumberInput,
  selectors: [["dx-number-input"]],
  inputs: {
    disabled: "disabled",
    errormessage: "errormessage",
    kisynced: "kisynced",
    label: "label",
    limitlength: "limitlength",
    max: "max",
    min: "min",
    nominmaxlabel: "nominmaxlabel",
    placeholder: "placeholder",
    precision: "precision",
    profile: "profile",
    readonly: "readonly",
    required: "required",
    size: "size",
    step: "step",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    thousandseparator: "thousandseparator",
    unit: "unit",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange",
    valueComplete: "valueComplete",
    errorFound: "errorFound"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxNumberInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxNumberInput = __decorate([ProxyCmp({
  inputs: ['disabled', 'errormessage', 'kisynced', 'label', 'limitlength', 'max', 'min', 'nominmaxlabel', 'placeholder', 'precision', 'profile', 'readonly', 'required', 'size', 'step', 'templateindicatortype', 'templatevalue', 'thousandseparator', 'unit', 'value'],
  methods: ['focusControl']
})], DxNumberInput);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxNumberInput, [{
    type: Component,
    args: [{
      selector: 'dx-number-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'errormessage', 'kisynced', 'label', 'limitlength', 'max', 'min', 'nominmaxlabel', 'placeholder', 'precision', 'profile', 'readonly', 'required', 'size', 'step', 'templateindicatortype', 'templatevalue', 'thousandseparator', 'unit', 'value'],
      outputs: ["valueChange", "valueComplete", "errorFound"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxNumberPaginator = class DxNumberPaginator {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['pageChanged']);
  }
};
DxNumberPaginator.ɵfac = function DxNumberPaginator_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxNumberPaginator)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxNumberPaginator.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxNumberPaginator,
  selectors: [["dx-number-paginator"]],
  inputs: {
    count: "count",
    current: "current"
  },
  outputs: {
    pageChanged: "pageChanged"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxNumberPaginator_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxNumberPaginator = __decorate([ProxyCmp({
  inputs: ['count', 'current']
})], DxNumberPaginator);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxNumberPaginator, [{
    type: Component,
    args: [{
      selector: 'dx-number-paginator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['count', 'current'],
      outputs: ["pageChanged"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxNumberPicker = class DxNumberPicker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
DxNumberPicker.ɵfac = function DxNumberPicker_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxNumberPicker)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxNumberPicker.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxNumberPicker,
  selectors: [["dx-number-picker"]],
  inputs: {
    align: "align",
    value: "value",
    values: "values"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxNumberPicker_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxNumberPicker = __decorate([ProxyCmp({
  inputs: ['align', 'value', 'values']
})], DxNumberPicker);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxNumberPicker, [{
    type: Component,
    args: [{
      selector: 'dx-number-picker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['align', 'value', 'values'],
      outputs: ["valueChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxOverflowMenu = class DxOverflowMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selection', 'listOpenChange']);
  }
};
DxOverflowMenu.ɵfac = function DxOverflowMenu_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxOverflowMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxOverflowMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxOverflowMenu,
  selectors: [["dx-overflow-menu"]],
  inputs: {
    align: "align",
    keeplistitemtypes: "keeplistitemtypes",
    label: "label",
    maxlabels: "maxlabels",
    maxselections: "maxselections",
    selectionmode: "selectionmode"
  },
  outputs: {
    selection: "selection",
    listOpenChange: "listOpenChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxOverflowMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxOverflowMenu = __decorate([ProxyCmp({
  inputs: ['align', 'keeplistitemtypes', 'label', 'maxlabels', 'maxselections', 'selectionmode']
})], DxOverflowMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxOverflowMenu, [{
    type: Component,
    args: [{
      selector: 'dx-overflow-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['align', 'keeplistitemtypes', 'label', 'maxlabels', 'maxselections', 'selectionmode'],
      outputs: ["selection", "listOpenChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxOverflowMenuItem = class DxOverflowMenuItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxOverflowMenuItem.ɵfac = function DxOverflowMenuItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxOverflowMenuItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxOverflowMenuItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxOverflowMenuItem,
  selectors: [["dx-overflow-menu-item"]],
  inputs: {
    disabled: "disabled",
    icon: "icon",
    label: "label",
    prelabel: "prelabel",
    selected: "selected",
    sublabel: "sublabel",
    theme: "theme",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxOverflowMenuItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxOverflowMenuItem = __decorate([ProxyCmp({
  inputs: ['disabled', 'icon', 'label', 'prelabel', 'selected', 'sublabel', 'theme', 'value']
})], DxOverflowMenuItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxOverflowMenuItem, [{
    type: Component,
    args: [{
      selector: 'dx-overflow-menu-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'icon', 'label', 'prelabel', 'selected', 'sublabel', 'theme', 'value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxPaginator = class DxPaginator {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['pageChanged']);
  }
};
DxPaginator.ɵfac = function DxPaginator_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxPaginator)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxPaginator.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxPaginator,
  selectors: [["dx-paginator"]],
  inputs: {
    count: "count",
    current: "current"
  },
  outputs: {
    pageChanged: "pageChanged"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxPaginator_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxPaginator = __decorate([ProxyCmp({
  inputs: ['count', 'current']
})], DxPaginator);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxPaginator, [{
    type: Component,
    args: [{
      selector: 'dx-paginator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['count', 'current'],
      outputs: ["pageChanged"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxPartnerLogo = class DxPartnerLogo {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxPartnerLogo.ɵfac = function DxPartnerLogo_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxPartnerLogo)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxPartnerLogo.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxPartnerLogo,
  selectors: [["dx-partner-logo"]],
  inputs: {
    customlogo: "customlogo",
    logo: "logo",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxPartnerLogo_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxPartnerLogo = __decorate([ProxyCmp({
  inputs: ['customlogo', 'logo', 'size']
})], DxPartnerLogo);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxPartnerLogo, [{
    type: Component,
    args: [{
      selector: 'dx-partner-logo',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['customlogo', 'logo', 'size'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxPasswordCriteria = class DxPasswordCriteria {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxPasswordCriteria.ɵfac = function DxPasswordCriteria_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxPasswordCriteria)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxPasswordCriteria.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxPasswordCriteria,
  selectors: [["dx-password-criteria"]],
  inputs: {
    checked: "checked",
    criteria: "criteria"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxPasswordCriteria_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxPasswordCriteria = __decorate([ProxyCmp({
  inputs: ['checked', 'criteria']
})], DxPasswordCriteria);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxPasswordCriteria, [{
    type: Component,
    args: [{
      selector: 'dx-password-criteria',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'criteria'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxPasswordCriteriaDisplay = class DxPasswordCriteriaDisplay {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxPasswordCriteriaDisplay.ɵfac = function DxPasswordCriteriaDisplay_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxPasswordCriteriaDisplay)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxPasswordCriteriaDisplay.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxPasswordCriteriaDisplay,
  selectors: [["dx-password-criteria-display"]],
  inputs: {
    layout: "layout"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxPasswordCriteriaDisplay_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxPasswordCriteriaDisplay = __decorate([ProxyCmp({
  inputs: ['layout']
})], DxPasswordCriteriaDisplay);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxPasswordCriteriaDisplay, [{
    type: Component,
    args: [{
      selector: 'dx-password-criteria-display',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['layout'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxPasswordInput = class DxPasswordInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'valueComplete']);
  }
};
DxPasswordInput.ɵfac = function DxPasswordInput_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxPasswordInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxPasswordInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxPasswordInput,
  selectors: [["dx-password-input"]],
  inputs: {
    disabled: "disabled",
    errormessage: "errormessage",
    label: "label",
    placeholder: "placeholder",
    required: "required",
    size: "size",
    strength: "strength",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange",
    valueComplete: "valueComplete"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxPasswordInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxPasswordInput = __decorate([ProxyCmp({
  inputs: ['disabled', 'errormessage', 'label', 'placeholder', 'required', 'size', 'strength', 'value'],
  methods: ['focusControl']
})], DxPasswordInput);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxPasswordInput, [{
    type: Component,
    args: [{
      selector: 'dx-password-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'errormessage', 'label', 'placeholder', 'required', 'size', 'strength', 'value'],
      outputs: ["valueChange", "valueComplete"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxPlaceholder = class DxPlaceholder {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxPlaceholder.ɵfac = function DxPlaceholder_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxPlaceholder)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxPlaceholder.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxPlaceholder,
  selectors: [["dx-placeholder"]],
  inputs: {
    box: "box",
    card: "card"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxPlaceholder_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxPlaceholder = __decorate([ProxyCmp({
  inputs: ['box', 'card']
})], DxPlaceholder);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxPlaceholder, [{
    type: Component,
    args: [{
      selector: 'dx-placeholder',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['box', 'card'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxPopover = class DxPopover {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['willClose', 'visibleChange', 'visibilityStateChange']);
  }
};
DxPopover.ɵfac = function DxPopover_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxPopover)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxPopover.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxPopover,
  selectors: [["dx-popover"]],
  inputs: {
    side: "side",
    visible: "visible"
  },
  outputs: {
    willClose: "willClose",
    visibleChange: "visibleChange",
    visibilityStateChange: "visibilityStateChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxPopover_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxPopover = __decorate([ProxyCmp({
  inputs: ['side', 'visible']
})], DxPopover);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxPopover, [{
    type: Component,
    args: [{
      selector: 'dx-popover',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['side', 'visible'],
      outputs: ["willClose", "visibleChange", "visibilityStateChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxProcessHeader = class DxProcessHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxProcessHeader.ɵfac = function DxProcessHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxProcessHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxProcessHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxProcessHeader,
  selectors: [["dx-process-header"]],
  inputs: {
    headline: "headline",
    peopleprefix: "peopleprefix",
    sublabel: "sublabel"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxProcessHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxProcessHeader = __decorate([ProxyCmp({
  inputs: ['headline', 'peopleprefix', 'sublabel']
})], DxProcessHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxProcessHeader, [{
    type: Component,
    args: [{
      selector: 'dx-process-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['headline', 'peopleprefix', 'sublabel'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxProcessHeaderItem = class DxProcessHeaderItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxProcessHeaderItem.ɵfac = function DxProcessHeaderItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxProcessHeaderItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxProcessHeaderItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxProcessHeaderItem,
  selectors: [["dx-process-header-item"]],
  inputs: {
    label: "label"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxProcessHeaderItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxProcessHeaderItem = __decorate([ProxyCmp({
  inputs: ['label']
})], DxProcessHeaderItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxProcessHeaderItem, [{
    type: Component,
    args: [{
      selector: 'dx-process-header-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['label'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxRadioButton = class DxRadioButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxRadioButton.ɵfac = function DxRadioButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxRadioButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxRadioButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxRadioButton,
  selectors: [["dx-radio-button"]],
  inputs: {
    disabled: "disabled",
    label: "label",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxRadioButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxRadioButton = __decorate([ProxyCmp({
  inputs: ['disabled', 'label', 'value'],
  methods: ['focusControl']
})], DxRadioButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxRadioButton, [{
    type: Component,
    args: [{
      selector: 'dx-radio-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'label', 'value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxRadioButtonGroup = class DxRadioButtonGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
DxRadioButtonGroup.ɵfac = function DxRadioButtonGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxRadioButtonGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxRadioButtonGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxRadioButtonGroup,
  selectors: [["dx-radio-button-group"]],
  inputs: {
    disabled: "disabled",
    errormessage: "errormessage",
    kisynced: "kisynced",
    label: "label",
    readonly: "readonly",
    required: "required",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxRadioButtonGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxRadioButtonGroup = __decorate([ProxyCmp({
  inputs: ['disabled', 'errormessage', 'kisynced', 'label', 'readonly', 'required', 'templateindicatortype', 'templatevalue', 'value'],
  methods: ['focusControl']
})], DxRadioButtonGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxRadioButtonGroup, [{
    type: Component,
    args: [{
      selector: 'dx-radio-button-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'errormessage', 'kisynced', 'label', 'readonly', 'required', 'templateindicatortype', 'templatevalue', 'value'],
      outputs: ["valueChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxRepresentativeInfo = class DxRepresentativeInfo {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxRepresentativeInfo.ɵfac = function DxRepresentativeInfo_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxRepresentativeInfo)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxRepresentativeInfo.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxRepresentativeInfo,
  selectors: [["dx-representative-info"]],
  inputs: {
    displaylocation: "displaylocation",
    name: "name",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxRepresentativeInfo_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxRepresentativeInfo = __decorate([ProxyCmp({
  inputs: ['displaylocation', 'name', 'type']
})], DxRepresentativeInfo);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxRepresentativeInfo, [{
    type: Component,
    args: [{
      selector: 'dx-representative-info',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['displaylocation', 'name', 'type'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSectionHeadline = class DxSectionHeadline {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSectionHeadline.ɵfac = function DxSectionHeadline_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSectionHeadline)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSectionHeadline.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSectionHeadline,
  selectors: [["dx-section-headline"]],
  inputs: {
    contentbelow: "contentbelow",
    headinglevel: "headinglevel",
    headingleveloverride: "headingleveloverride",
    headline: "headline",
    icon: "icon",
    required: "required"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSectionHeadline_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSectionHeadline = __decorate([ProxyCmp({
  inputs: ['contentbelow', 'headinglevel', 'headingleveloverride', 'headline', 'icon', 'required']
})], DxSectionHeadline);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSectionHeadline, [{
    type: Component,
    args: [{
      selector: 'dx-section-headline',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentbelow', 'headinglevel', 'headingleveloverride', 'headline', 'icon', 'required'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSidebarContent = class DxSidebarContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSidebarContent.ɵfac = function DxSidebarContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSidebarContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSidebarContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSidebarContent,
  selectors: [["dx-sidebar-content"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSidebarContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSidebarContent = __decorate([ProxyCmp({})], DxSidebarContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSidebarContent, [{
    type: Component,
    args: [{
      selector: 'dx-sidebar-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSingleSelect = class DxSingleSelect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
DxSingleSelect.ɵfac = function DxSingleSelect_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSingleSelect)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSingleSelect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSingleSelect,
  selectors: [["dx-single-select"]],
  inputs: {
    columns: "columns",
    disabled: "disabled",
    errormessage: "errormessage",
    iconsize: "iconsize",
    kisynced: "kisynced",
    label: "label",
    readonly: "readonly",
    required: "required",
    size: "size",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    togglemode: "togglemode",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSingleSelect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSingleSelect = __decorate([ProxyCmp({
  inputs: ['columns', 'disabled', 'errormessage', 'iconsize', 'kisynced', 'label', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'togglemode', 'value'],
  methods: ['focusControl']
})], DxSingleSelect);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSingleSelect, [{
    type: Component,
    args: [{
      selector: 'dx-single-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['columns', 'disabled', 'errormessage', 'iconsize', 'kisynced', 'label', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'togglemode', 'value'],
      outputs: ["valueChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSingleSelectItem = class DxSingleSelectItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSingleSelectItem.ɵfac = function DxSingleSelectItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSingleSelectItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSingleSelectItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSingleSelectItem,
  selectors: [["dx-single-select-item"]],
  inputs: {
    disabled: "disabled",
    icon: "icon",
    label: "label",
    sublabel: "sublabel",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSingleSelectItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSingleSelectItem = __decorate([ProxyCmp({
  inputs: ['disabled', 'icon', 'label', 'sublabel', 'value'],
  methods: ['focusControl']
})], DxSingleSelectItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSingleSelectItem, [{
    type: Component,
    args: [{
      selector: 'dx-single-select-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'icon', 'label', 'sublabel', 'value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSiteNavigation = class DxSiteNavigation {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSiteNavigation.ɵfac = function DxSiteNavigation_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSiteNavigation)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSiteNavigation.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSiteNavigation,
  selectors: [["dx-site-navigation"]],
  inputs: {
    expandedlayoutat: "expandedlayoutat",
    menucolumns: "menucolumns"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSiteNavigation_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSiteNavigation = __decorate([ProxyCmp({
  inputs: ['expandedlayoutat', 'menucolumns']
})], DxSiteNavigation);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSiteNavigation, [{
    type: Component,
    args: [{
      selector: 'dx-site-navigation',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['expandedlayoutat', 'menucolumns'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSiteNavigationMenu = class DxSiteNavigationMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['navigate', 'autoNavigationTimeout']);
  }
};
DxSiteNavigationMenu.ɵfac = function DxSiteNavigationMenu_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSiteNavigationMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSiteNavigationMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSiteNavigationMenu,
  selectors: [["dx-site-navigation-menu"]],
  inputs: {
    autonavigation: "autonavigation",
    selectedvalue: "selectedvalue",
    titlestring: "titlestring"
  },
  outputs: {
    navigate: "navigate",
    autoNavigationTimeout: "autoNavigationTimeout"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSiteNavigationMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSiteNavigationMenu = __decorate([ProxyCmp({
  inputs: ['autonavigation', 'selectedvalue', 'titlestring']
})], DxSiteNavigationMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSiteNavigationMenu, [{
    type: Component,
    args: [{
      selector: 'dx-site-navigation-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autonavigation', 'selectedvalue', 'titlestring'],
      outputs: ["navigate", "autoNavigationTimeout"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSiteNavigationMenuItem = class DxSiteNavigationMenuItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSiteNavigationMenuItem.ɵfac = function DxSiteNavigationMenuItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSiteNavigationMenuItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSiteNavigationMenuItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSiteNavigationMenuItem,
  selectors: [["dx-site-navigation-menu-item"]],
  inputs: {
    disabled: "disabled",
    label: "label",
    state: "state",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSiteNavigationMenuItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSiteNavigationMenuItem = __decorate([ProxyCmp({
  inputs: ['disabled', 'label', 'state', 'value']
})], DxSiteNavigationMenuItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSiteNavigationMenuItem, [{
    type: Component,
    args: [{
      selector: 'dx-site-navigation-menu-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'label', 'state', 'value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSiteNavigationScope = class DxSiteNavigationScope {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSiteNavigationScope.ɵfac = function DxSiteNavigationScope_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSiteNavigationScope)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSiteNavigationScope.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSiteNavigationScope,
  selectors: [["dx-site-navigation-scope"]],
  inputs: {
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSiteNavigationScope_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSiteNavigationScope = __decorate([ProxyCmp({
  inputs: ['value']
})], DxSiteNavigationScope);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSiteNavigationScope, [{
    type: Component,
    args: [{
      selector: 'dx-site-navigation-scope',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSkeleton = class DxSkeleton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSkeleton.ɵfac = function DxSkeleton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSkeleton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSkeleton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSkeleton,
  selectors: [["dx-skeleton"]],
  inputs: {
    height: "height",
    type: "type",
    width: "width"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSkeleton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSkeleton = __decorate([ProxyCmp({
  inputs: ['height', 'type', 'width']
})], DxSkeleton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSkeleton, [{
    type: Component,
    args: [{
      selector: 'dx-skeleton',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['height', 'type', 'width'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSlideShow = class DxSlideShow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['pageChange']);
  }
};
DxSlideShow.ɵfac = function DxSlideShow_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSlideShow)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSlideShow.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSlideShow,
  selectors: [["dx-slide-show"]],
  inputs: {
    amount: "amount",
    initialpage: "initialpage",
    mq1amount: "mq1amount",
    mq2amount: "mq2amount",
    mq3amount: "mq3amount",
    mq4amount: "mq4amount",
    mq5amount: "mq5amount",
    spacebetween: "spacebetween",
    viewmode: "viewmode"
  },
  outputs: {
    pageChange: "pageChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSlideShow_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSlideShow = __decorate([ProxyCmp({
  inputs: ['amount', 'initialpage', 'mq1amount', 'mq2amount', 'mq3amount', 'mq4amount', 'mq5amount', 'spacebetween', 'viewmode'],
  methods: ['setPage']
})], DxSlideShow);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSlideShow, [{
    type: Component,
    args: [{
      selector: 'dx-slide-show',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['amount', 'initialpage', 'mq1amount', 'mq2amount', 'mq3amount', 'mq4amount', 'mq5amount', 'spacebetween', 'viewmode'],
      outputs: ["pageChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSlider = class DxSlider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
DxSlider.ɵfac = function DxSlider_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSlider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSlider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSlider,
  selectors: [["dx-slider"]],
  inputs: {
    disabled: "disabled",
    errormessage: "errormessage",
    kisynced: "kisynced",
    knoblabel: "knoblabel",
    label: "label",
    max: "max",
    min: "min",
    readonly: "readonly",
    required: "required",
    showminmaxlabel: "showminmaxlabel",
    showvaluelabel: "showvaluelabel",
    step: "step",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSlider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSlider = __decorate([ProxyCmp({
  inputs: ['disabled', 'errormessage', 'kisynced', 'knoblabel', 'label', 'max', 'min', 'readonly', 'required', 'showminmaxlabel', 'showvaluelabel', 'step', 'templateindicatortype', 'templatevalue', 'value']
})], DxSlider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSlider, [{
    type: Component,
    args: [{
      selector: 'dx-slider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'errormessage', 'kisynced', 'knoblabel', 'label', 'max', 'min', 'readonly', 'required', 'showminmaxlabel', 'showvaluelabel', 'step', 'templateindicatortype', 'templatevalue', 'value'],
      outputs: ["valueChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSort = class DxSort {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sort']);
  }
};
DxSort.ɵfac = function DxSort_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSort)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSort.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSort,
  selectors: [["dx-sort"]],
  inputs: {
    sortable: "sortable"
  },
  outputs: {
    sort: "sort"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSort_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSort = __decorate([ProxyCmp({
  inputs: ['sortable']
})], DxSort);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSort, [{
    type: Component,
    args: [{
      selector: 'dx-sort',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['sortable'],
      outputs: ["sort"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSortItem = class DxSortItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSortItem.ɵfac = function DxSortItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSortItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSortItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSortItem,
  selectors: [["dx-sort-item"]],
  inputs: {
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSortItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSortItem = __decorate([ProxyCmp({
  inputs: ['value']
})], DxSortItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSortItem, [{
    type: Component,
    args: [{
      selector: 'dx-sort-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSpacer = class DxSpacer {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSpacer.ɵfac = function DxSpacer_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSpacer)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSpacer.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSpacer,
  selectors: [["dx-spacer"]],
  inputs: {
    mq1: "mq1",
    mq2: "mq2",
    mq3: "mq3",
    mq4: "mq4",
    mq5: "mq5",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSpacer_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSpacer = __decorate([ProxyCmp({
  inputs: ['mq1', 'mq2', 'mq3', 'mq4', 'mq5', 'size']
})], DxSpacer);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSpacer, [{
    type: Component,
    args: [{
      selector: 'dx-spacer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['mq1', 'mq2', 'mq3', 'mq4', 'mq5', 'size'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxStateIcon = class DxStateIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxStateIcon.ɵfac = function DxStateIcon_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxStateIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxStateIcon.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxStateIcon,
  selectors: [["dx-state-icon"]],
  inputs: {
    state: "state"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxStateIcon_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxStateIcon = __decorate([ProxyCmp({
  inputs: ['state']
})], DxStateIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxStateIcon, [{
    type: Component,
    args: [{
      selector: 'dx-state-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['state'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxSwitch = class DxSwitch {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxSwitch.ɵfac = function DxSwitch_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxSwitch)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxSwitch.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxSwitch,
  selectors: [["dx-switch"]],
  inputs: {
    case: "case"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxSwitch_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxSwitch = __decorate([ProxyCmp({
  inputs: ['case']
})], DxSwitch);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSwitch, [{
    type: Component,
    args: [{
      selector: 'dx-switch',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['case'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTabBar = class DxTabBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tabChange']);
  }
};
DxTabBar.ɵfac = function DxTabBar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTabBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTabBar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTabBar,
  selectors: [["dx-tab-bar"]],
  inputs: {
    selected: "selected"
  },
  outputs: {
    tabChange: "tabChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTabBar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTabBar = __decorate([ProxyCmp({
  inputs: ['selected']
})], DxTabBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTabBar, [{
    type: Component,
    args: [{
      selector: 'dx-tab-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['selected'],
      outputs: ["tabChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTabPage = class DxTabPage {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxTabPage.ɵfac = function DxTabPage_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTabPage)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTabPage.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTabPage,
  selectors: [["dx-tab-page"]],
  inputs: {
    icon: "icon",
    label: "label",
    state: "state",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTabPage_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTabPage = __decorate([ProxyCmp({
  inputs: ['icon', 'label', 'state', 'value']
})], DxTabPage);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTabPage, [{
    type: Component,
    args: [{
      selector: 'dx-tab-page',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['icon', 'label', 'state', 'value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTable = class DxTable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tableStateChanged', 'selectionStateChanged']);
  }
};
DxTable.ɵfac = function DxTable_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTable.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTable,
  selectors: [["dx-table"]],
  inputs: {
    initialsort: "initialsort",
    initialsortdirection: "initialsortdirection",
    mode: "mode",
    pagesize: "pagesize",
    pagesizes: "pagesizes",
    rowcount: "rowcount",
    scrollable: "scrollable",
    selectionenabled: "selectionenabled",
    selectionmode: "selectionmode",
    sortcontrolposition: "sortcontrolposition",
    unreadcolor: "unreadcolor"
  },
  outputs: {
    tableStateChanged: "tableStateChanged",
    selectionStateChanged: "selectionStateChanged"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTable_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTable = __decorate([ProxyCmp({
  inputs: ['initialsort', 'initialsortdirection', 'mode', 'pagesize', 'pagesizes', 'rowcount', 'scrollable', 'selectionenabled', 'selectionmode', 'sortcontrolposition', 'unreadcolor'],
  methods: ['getState', 'setState']
})], DxTable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTable, [{
    type: Component,
    args: [{
      selector: 'dx-table',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['initialsort', 'initialsortdirection', 'mode', 'pagesize', 'pagesizes', 'rowcount', 'scrollable', 'selectionenabled', 'selectionmode', 'sortcontrolposition', 'unreadcolor'],
      outputs: ["tableStateChanged", "selectionStateChanged"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTableCell = class DxTableCell {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxTableCell.ɵfac = function DxTableCell_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTableCell)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTableCell.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTableCell,
  selectors: [["dx-table-cell"]],
  inputs: {
    align: "align",
    color: "color",
    editable: "editable",
    type: "type",
    unit: "unit",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTableCell_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTableCell = __decorate([ProxyCmp({
  inputs: ['align', 'color', 'editable', 'type', 'unit', 'value']
})], DxTableCell);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTableCell, [{
    type: Component,
    args: [{
      selector: 'dx-table-cell',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['align', 'color', 'editable', 'type', 'unit', 'value'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTableFooter = class DxTableFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxTableFooter.ɵfac = function DxTableFooter_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTableFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTableFooter.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTableFooter,
  selectors: [["dx-table-footer"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTableFooter_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTableFooter = __decorate([ProxyCmp({})], DxTableFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTableFooter, [{
    type: Component,
    args: [{
      selector: 'dx-table-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTableHeader = class DxTableHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxTableHeader.ɵfac = function DxTableHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTableHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTableHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTableHeader,
  selectors: [["dx-table-header"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTableHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTableHeader = __decorate([ProxyCmp({})], DxTableHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTableHeader, [{
    type: Component,
    args: [{
      selector: 'dx-table-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTableHeaderItem = class DxTableHeaderItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxTableHeaderItem.ɵfac = function DxTableHeaderItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTableHeaderItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTableHeaderItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTableHeaderItem,
  selectors: [["dx-table-header-item"]],
  inputs: {
    align: "align",
    columnid: "columnid",
    customSort: "customSort",
    infoposition: "infoposition",
    label: "label",
    linesize: "linesize",
    maxwidth: "maxwidth",
    minwidth: "minwidth",
    required: "required",
    sortable: "sortable",
    sortpriority: "sortpriority",
    sticky: "sticky",
    type: "type",
    unitposition: "unitposition",
    valueautomargin: "valueautomargin"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTableHeaderItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTableHeaderItem = __decorate([ProxyCmp({
  inputs: ['align', 'columnid', 'customSort', 'infoposition', 'label', 'linesize', 'maxwidth', 'minwidth', 'required', 'sortable', 'sortpriority', 'sticky', 'type', 'unitposition', 'valueautomargin'],
  methods: ['getMinWidth', 'getMaxWidth']
})], DxTableHeaderItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTableHeaderItem, [{
    type: Component,
    args: [{
      selector: 'dx-table-header-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['align', 'columnid', 'customSort', 'infoposition', 'label', 'linesize', 'maxwidth', 'minwidth', 'required', 'sortable', 'sortpriority', 'sticky', 'type', 'unitposition', 'valueautomargin'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTableRow = class DxTableRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['rowSelected', 'openChange']);
  }
};
DxTableRow.ɵfac = function DxTableRow_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTableRow)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTableRow.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTableRow,
  selectors: [["dx-table-row"]],
  inputs: {
    open: "open",
    rowid: "rowid",
    selected: "selected",
    unread: "unread"
  },
  outputs: {
    rowSelected: "rowSelected",
    openChange: "openChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTableRow_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTableRow = __decorate([ProxyCmp({
  inputs: ['open', 'rowid', 'selected', 'unread']
})], DxTableRow);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTableRow, [{
    type: Component,
    args: [{
      selector: 'dx-table-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['open', 'rowid', 'selected', 'unread'],
      outputs: ["rowSelected", "openChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTag = class DxTag {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['remove', 'selectChange', 'selectedChange']);
  }
};
DxTag.ɵfac = function DxTag_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTag)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTag.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTag,
  selectors: [["dx-tag"]],
  inputs: {
    color: "color",
    label: "label",
    maxlength: "maxlength",
    removeable: "removeable",
    selectable: "selectable",
    selected: "selected",
    textcolor: "textcolor",
    theme: "theme",
    type: "type"
  },
  outputs: {
    remove: "remove",
    selectChange: "selectChange",
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTag_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTag = __decorate([ProxyCmp({
  inputs: ['color', 'label', 'maxlength', 'removeable', 'selectable', 'selected', 'textcolor', 'theme', 'type']
})], DxTag);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTag, [{
    type: Component,
    args: [{
      selector: 'dx-tag',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'label', 'maxlength', 'removeable', 'selectable', 'selected', 'textcolor', 'theme', 'type'],
      outputs: ["remove", "selectChange", "selectedChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxText = class DxText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxText.ɵfac = function DxText_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxText)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxText.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxText,
  selectors: [["dx-text"]],
  inputs: {
    color: "color",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxText_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxText = __decorate([ProxyCmp({
  inputs: ['color', 'type']
})], DxText);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxText, [{
    type: Component,
    args: [{
      selector: 'dx-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'type'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTextArea = class DxTextArea {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'valueComplete']);
  }
};
DxTextArea.ɵfac = function DxTextArea_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTextArea)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTextArea.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTextArea,
  selectors: [["dx-text-area"]],
  inputs: {
    disabled: "disabled",
    errormessage: "errormessage",
    kisynced: "kisynced",
    label: "label",
    lines: "lines",
    maxlength: "maxlength",
    nominmaxlabel: "nominmaxlabel",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange",
    valueComplete: "valueComplete"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTextArea_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTextArea = __decorate([ProxyCmp({
  inputs: ['disabled', 'errormessage', 'kisynced', 'label', 'lines', 'maxlength', 'nominmaxlabel', 'placeholder', 'readonly', 'required', 'templateindicatortype', 'templatevalue', 'value'],
  methods: ['focusControl']
})], DxTextArea);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTextArea, [{
    type: Component,
    args: [{
      selector: 'dx-text-area',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'errormessage', 'kisynced', 'label', 'lines', 'maxlength', 'nominmaxlabel', 'placeholder', 'readonly', 'required', 'templateindicatortype', 'templatevalue', 'value'],
      outputs: ["valueChange", "valueComplete"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTextInput = class DxTextInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'valueComplete', 'autocomplete', 'selectAutocomplete', 'enterPress']);
  }
};
DxTextInput.ɵfac = function DxTextInput_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTextInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTextInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTextInput,
  selectors: [["dx-text-input"]],
  inputs: {
    disabled: "disabled",
    errormessage: "errormessage",
    icon: "icon",
    inputmode: "inputmode",
    kisynced: "kisynced",
    label: "label",
    maxlength: "maxlength",
    nominmaxlabel: "nominmaxlabel",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    size: "size",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange",
    valueComplete: "valueComplete",
    autocomplete: "autocomplete",
    selectAutocomplete: "selectAutocomplete",
    enterPress: "enterPress"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTextInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTextInput = __decorate([ProxyCmp({
  inputs: ['disabled', 'errormessage', 'icon', 'inputmode', 'kisynced', 'label', 'maxlength', 'nominmaxlabel', 'placeholder', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'value'],
  methods: ['focusControl']
})], DxTextInput);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTextInput, [{
    type: Component,
    args: [{
      selector: 'dx-text-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'errormessage', 'icon', 'inputmode', 'kisynced', 'label', 'maxlength', 'nominmaxlabel', 'placeholder', 'readonly', 'required', 'size', 'templateindicatortype', 'templatevalue', 'value'],
      outputs: ["valueChange", "valueComplete", "autocomplete", "selectAutocomplete", "enterPress"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxTextList = class DxTextList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxTextList.ɵfac = function DxTextList_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxTextList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxTextList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxTextList,
  selectors: [["dx-text-list"]],
  inputs: {
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxTextList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxTextList = __decorate([ProxyCmp({
  inputs: ['type']
})], DxTextList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxTextList, [{
    type: Component,
    args: [{
      selector: 'dx-text-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['type'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxToggle = class DxToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['checkedChange']);
  }
};
DxToggle.ɵfac = function DxToggle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxToggle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxToggle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxToggle,
  selectors: [["dx-toggle"]],
  inputs: {
    checked: "checked",
    disabled: "disabled",
    readonly: "readonly",
    templateindicatortype: "templateindicatortype",
    templatevalue: "templatevalue"
  },
  outputs: {
    checkedChange: "checkedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxToggle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxToggle = __decorate([ProxyCmp({
  inputs: ['checked', 'disabled', 'readonly', 'templateindicatortype', 'templatevalue']
})], DxToggle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxToggle, [{
    type: Component,
    args: [{
      selector: 'dx-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'readonly', 'templateindicatortype', 'templatevalue'],
      outputs: ["checkedChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxUserAvatar = class DxUserAvatar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
DxUserAvatar.ɵfac = function DxUserAvatar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxUserAvatar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxUserAvatar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxUserAvatar,
  selectors: [["dx-user-avatar"]],
  inputs: {
    imageurl: "imageurl",
    initials: "initials",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxUserAvatar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxUserAvatar = __decorate([ProxyCmp({
  inputs: ['imageurl', 'initials', 'size']
})], DxUserAvatar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxUserAvatar, [{
    type: Component,
    args: [{
      selector: 'dx-user-avatar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['imageurl', 'initials', 'size'],
      outputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxWizard = class DxWizard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['currentStepChange']);
  }
};
DxWizard.ɵfac = function DxWizard_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxWizard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxWizard.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxWizard,
  selectors: [["dx-wizard"]],
  inputs: {
    currentstep: "currentstep",
    expandedlayoutat: "expandedlayoutat",
    headline: "headline",
    orientation: "orientation"
  },
  outputs: {
    currentStepChange: "currentStepChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxWizard_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxWizard = __decorate([ProxyCmp({
  inputs: ['currentstep', 'expandedlayoutat', 'headline', 'orientation']
})], DxWizard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxWizard, [{
    type: Component,
    args: [{
      selector: 'dx-wizard',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['currentstep', 'expandedlayoutat', 'headline', 'orientation'],
      outputs: ["currentStepChange"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let DxWizardStep = class DxWizardStep {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['interaction']);
  }
};
DxWizardStep.ɵfac = function DxWizardStep_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DxWizardStep)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DxWizardStep.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DxWizardStep,
  selectors: [["dx-wizard-step"]],
  inputs: {
    finished: "finished",
    label: "label",
    value: "value"
  },
  outputs: {
    interaction: "interaction"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function DxWizardStep_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DxWizardStep = __decorate([ProxyCmp({
  inputs: ['finished', 'label', 'value']
})], DxWizardStep);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxWizardStep, [{
    type: Component,
    args: [{
      selector: 'dx-wizard-step',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['finished', 'label', 'value'],
      outputs: ["interaction"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
const DIRECTIVES = [DxAccordion, DxAccordionGroup, DxAccordionLegend, DxAlert, DxAnnouncementBar, DxApp, DxBreadcrumb, DxBreadcrumbItem, DxButton, DxCard, DxCardCollapsable, DxCardContent, DxCardHeader, DxCardImage, DxChartSample, DxCheckbox, DxCheckboxGroup, DxCombobox, DxContainer, DxCookieBanner, DxCookieChoice, DxDateInput, DxDateRange, DxDateTime, DxDateTimeRange, DxDateTimeSegment, DxDonutChart, DxDropdown, DxDropdownOption, DxErrorMessage, DxErrorPage, DxExpanded, DxFdOutlineBox, DxFdSeparator, DxFooter, DxFormWrapper, DxGoldenStage, DxGrid, DxHeader, DxHeaderAppFavorites, DxHeaderAppFavoritesApp, DxHeaderMenuCustom, DxHeaderMenuGroup, DxHeaderMenuItem, DxHeaderMenuLink, DxHeaderNavigation, DxHeaderNavigationItem, DxHeaderUser, DxHeroImage, DxIcon, DxInfo, DxLegend, DxLink, DxList, DxListItem, DxListSection, DxLoadingIndicator, DxLoadingIndicatorOverlay, DxLogo, DxMediaQueryDisplay, DxMediaQueryValue, DxModal, DxMultiDropdown, DxMultiDropdownOption, DxMultiSelect, DxMultiSelectItem, DxNotificationBanner, DxNotificationBar, DxNotificationToast, DxNumberInput, DxNumberPaginator, DxNumberPicker, DxOverflowMenu, DxOverflowMenuItem, DxPaginator, DxPartnerLogo, DxPasswordCriteria, DxPasswordCriteriaDisplay, DxPasswordInput, DxPlaceholder, DxPopover, DxProcessHeader, DxProcessHeaderItem, DxRadioButton, DxRadioButtonGroup, DxRepresentativeInfo, DxSectionHeadline, DxSidebarContent, DxSingleSelect, DxSingleSelectItem, DxSiteNavigation, DxSiteNavigationMenu, DxSiteNavigationMenuItem, DxSiteNavigationScope, DxSkeleton, DxSlideShow, DxSlider, DxSort, DxSortItem, DxSpacer, DxStateIcon, DxSwitch, DxTabBar, DxTabPage, DxTable, DxTableCell, DxTableFooter, DxTableHeader, DxTableHeaderItem, DxTableRow, DxTag, DxText, DxTextArea, DxTextInput, DxTextList, DxToggle, DxUserAvatar, DxWizard, DxWizardStep];
class ValueAccessor {
  constructor(el) {
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  writeValue(value) {
    this.el.nativeElement.value = this.lastValue = value == null ? '' : value;
  }
  handleChangeEvent(value) {
    if (value !== this.lastValue) {
      this.lastValue = value;
      this.onChange(value);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.el.nativeElement.disabled = isDisabled;
  }
}
ValueAccessor.ɵfac = function ValueAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
ValueAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ValueAccessor,
  hostBindings: function ValueAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("focusout", function ValueAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValueAccessor, [{
    type: Directive,
    args: [{}]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class NumericValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  registerOnChange(fn) {
    super.registerOnChange(value => {
      fn(value === '' || value == null || isNaN(value) ? null : parseFloat(value));
    });
  }
}
NumericValueAccessor.ɵfac = function NumericValueAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NumericValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
NumericValueAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NumericValueAccessor,
  selectors: [["dx-slider"], ["dx-number-input"]],
  hostBindings: function NumericValueAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function NumericValueAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.target.value);
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NumericValueAccessor,
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumericValueAccessor, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'dx-slider, dx-number-input',
      host: {
        '(valueChange)': 'handleChangeEvent($event.target.value)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumericValueAccessor,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, null);
})();
class TextValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
}
TextValueAccessor.ɵfac = function TextValueAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TextValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
TextValueAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TextValueAccessor,
  selectors: [["dx-dropdown"], ["dx-date-input"], ["dx-date-range"], ["dx-date-time"], ["dx-date-time-range"], ["dx-combobox"], ["dx-radio-button-group"], ["dx-text-input"], ["dx-text-area"], ["dx-single-select"], ["dx-password-input"]],
  hostBindings: function TextValueAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function TextValueAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.target.value);
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TextValueAccessor,
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextValueAccessor, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'dx-dropdown, dx-date-input, dx-date-range, dx-date-time, dx-date-time-range, dx-combobox, dx-radio-button-group, dx-text-input, dx-text-area, dx-single-select, dx-password-input',
      host: {
        '(valueChange)': 'handleChangeEvent($event.target.value)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessor,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, null);
})();
class BooleanValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  writeValue(value) {
    this.el.nativeElement.checked = this.lastValue = value == null ? false : value;
  }
}
BooleanValueAccessor.ɵfac = function BooleanValueAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BooleanValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
BooleanValueAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BooleanValueAccessor,
  selectors: [["dx-checkbox"], ["dx-toggle"], ["dx-cookie-choice"], ["dx-accordion"], ["dx-multi-dropdown-option"], ["dx-multi-select-item"], ["dx-list-item"], ["dx-table-row"]],
  hostBindings: function BooleanValueAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("checkedChange", function BooleanValueAccessor_checkedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.target.checked);
      })("openChange", function BooleanValueAccessor_openChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.target.checked);
      })("selectedChange", function BooleanValueAccessor_selectedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.target.checked);
      })("rowSelected", function BooleanValueAccessor_rowSelected_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.target.checked);
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: BooleanValueAccessor,
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BooleanValueAccessor, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'dx-checkbox, dx-toggle, dx-cookie-choice, dx-accordion, dx-multi-dropdown-option, dx-multi-select-item, dx-list-item, dx-table-row',
      host: {
        '(checkedChange)': 'handleChangeEvent($event.target.checked)',
        '(openChange)': 'handleChangeEvent($event.target.checked)',
        '(selectedChange)': 'handleChangeEvent($event.target.checked)',
        '(rowSelected)': 'handleChangeEvent($event.target.checked)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessor,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, null);
})();
const components = [...DIRECTIVES, NumericValueAccessor, TextValueAccessor, BooleanValueAccessor];
class DesignSystemModule {}
DesignSystemModule.ɵfac = function DesignSystemModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DesignSystemModule)();
};
DesignSystemModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DesignSystemModule
});
DesignSystemModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: () => defineCustomElements,
    multi: true
  }]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DesignSystemModule, [{
    type: NgModule,
    args: [{
      declarations: [components],
      imports: [],
      exports: components,
      providers: [{
        provide: APP_INITIALIZER,
        useFactory: () => defineCustomElements,
        multi: true
      }]
    }]
  }], null, null);
})();

/*
 * Public API Surface of design-system-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BooleanValueAccessor, DIRECTIVES, DesignSystemModule, DxAccordion, DxAccordionGroup, DxAccordionLegend, DxAlert, DxAnnouncementBar, DxApp, DxBreadcrumb, DxBreadcrumbItem, DxButton, DxCard, DxCardCollapsable, DxCardContent, DxCardHeader, DxCardImage, DxChartSample, DxCheckbox, DxCheckboxGroup, DxCombobox, DxContainer, DxCookieBanner, DxCookieChoice, DxDateInput, DxDateRange, DxDateTime, DxDateTimeRange, DxDateTimeSegment, DxDonutChart, DxDropdown, DxDropdownOption, DxErrorMessage, DxErrorPage, DxExpanded, DxFdOutlineBox, DxFdSeparator, DxFooter, DxFormWrapper, DxGoldenStage, DxGrid, DxHeader, DxHeaderAppFavorites, DxHeaderAppFavoritesApp, DxHeaderMenuCustom, DxHeaderMenuGroup, DxHeaderMenuItem, DxHeaderMenuLink, DxHeaderNavigation, DxHeaderNavigationItem, DxHeaderUser, DxHeroImage, DxIcon, DxInfo, DxLegend, DxLink, DxList, DxListItem, DxListSection, DxLoadingIndicator, DxLoadingIndicatorOverlay, DxLogo, DxMediaQueryDisplay, DxMediaQueryValue, DxModal, DxMultiDropdown, DxMultiDropdownOption, DxMultiSelect, DxMultiSelectItem, DxNotificationBanner, DxNotificationBar, DxNotificationToast, DxNumberInput, DxNumberPaginator, DxNumberPicker, DxOverflowMenu, DxOverflowMenuItem, DxPaginator, DxPartnerLogo, DxPasswordCriteria, DxPasswordCriteriaDisplay, DxPasswordInput, DxPlaceholder, DxPopover, DxProcessHeader, DxProcessHeaderItem, DxRadioButton, DxRadioButtonGroup, DxRepresentativeInfo, DxSectionHeadline, DxSidebarContent, DxSingleSelect, DxSingleSelectItem, DxSiteNavigation, DxSiteNavigationMenu, DxSiteNavigationMenuItem, DxSiteNavigationScope, DxSkeleton, DxSlideShow, DxSlider, DxSort, DxSortItem, DxSpacer, DxStateIcon, DxSwitch, DxTabBar, DxTabPage, DxTable, DxTableCell, DxTableFooter, DxTableHeader, DxTableHeaderItem, DxTableRow, DxTag, DxText, DxTextArea, DxTextInput, DxTextList, DxToggle, DxUserAvatar, DxWizard, DxWizardStep, NumericValueAccessor, TextValueAccessor };
